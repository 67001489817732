import { graphql } from "gatsby";
import Price from "../purchase";

const PriceUpgrade = ({ data }) => <Price upgradePage data={data} />;

export default PriceUpgrade;

export const query = graphql`
  query getPurchaseUpgrade($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        bannerTitle
        bannerSubTitle
        saleTitle
        seo {
          description
          link
          title
          noForRobots
        }
        compareFooterTitle
        compareFooterDescription
        dataRowList {
          description
          heading
          img
          status
          title
          marker
        }
        benefits {
          title
          description
        }
        priceBlockList {
          best
          decimal
          description
          integer
          title
          link
          upgrade
          saleInteger
          saleDecimal
        }
        priceBlockListTooltip
        priceBlockListRemark
        tableHeadingThList
      }
    }
  }
`;
